import React from 'react';

import { useTranslation } from 'react-i18next';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';

const AccessDeniedPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title="Access Denied" />
      <h1>{t('access denied title')}</h1>
      <p>{t('access denied message')}</p>
    </Layout>
  );
};

export default AccessDeniedPage;
